<section class="abs-banner d-flex">
  <h1 class="text-center">Work Gallery</h1>
</section>
<section class="gallery-section">
  <div class="container">
    <div class="gallery-container">
      <div class="gallery">
        <!-- Loop through images and display them -->
        <div class="gallery-item" *ngFor="let image of images">
          <img [src]="image" alt="Gallery Image" (click)="openImage(image)">
        </div>
      </div>
      
      <!-- Lightbox for full image view -->
      <div class="lightbox" *ngIf="selectedImage" (click)="closeImage()">
        <img [src]="selectedImage" alt="Full Image">
      </div>
    </div>
  </div>
</section>
