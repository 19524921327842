<section class="abs-banner d-flex">
  <h1 class="text-center">Testimonials</h1>
</section>
<section class="testimonial-section">
  <div class="container">
    <div class="testimonial-slider-container">
      <h1 class="testimonial-slider-title">What Our Clients Say</h1>
      <div class="swiper-container mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let testimonial of testimonials">
            <div class="testimonial-item">
              <p class="testimonial-text">“{{ testimonial.text }}”</p>
              <p class="testimonial-author">- {{ testimonial.author }}</p>
              <img class="testimonial-image" [src]="testimonial.image" alt="{{ testimonial.author }}" />
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
        <!-- Add Navigation -->
        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
      </div>
    </div>
  </div>
</section>
