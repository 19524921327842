import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-intellectual-property-expert',
  templateUrl: './hire-intellectual-property-expert.component.html',
  styleUrls: ['./hire-intellectual-property-expert.component.scss']
})
export class HireIntellectualPropertyExpertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
