<section class="abs-banner d-flex">
  <h1 class="text-center">Blog</h1>
</section>
<section class="blog-section">
  <div class="container">
    <div class="blog-container">
      <div class="blog-posts">
        <div class="blog-post" *ngFor="let post of posts">
          <img class="post-image" [src]="post.image" alt="{{ post.title }}" width="100%" />
          <div class="post-content">
            <h2 class="post-title">{{ post.title }}</h2>
            <p class="post-date">{{ post.date | date: 'longDate' }}</p>
            <p class="post-excerpt">{{ post.excerpt }}</p>
            <a [routerLink]="['/blog', post.id]" class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
