import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-firmware-developers',
  templateUrl: './software-firmware-developers.component.html',
  styleUrls: ['./software-firmware-developers.component.scss']
})
export class SoftwareFirmwareDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
