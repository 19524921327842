<section class="abs-banner d-flex">
    <h1 class="text-center">Refunds Policy</h1>
</section>
<section class="refund-section">
    <div class="container">
        <h1>Refund Policy – ITcrayons.com</h1>
        <p>At ITcrayons.com, we prioritize transparency and fairness in all our dealings. Our refund policy is designed to ensure mutual respect and satisfaction throughout our partnership.</p>
    
        <h2>1. Contract Termination Notice</h2>
        <p>Either ITcrayons.com or the client may terminate the contract by providing a written notice at least 15 days in advance. If both parties agree, this notice period can be extended, ensuring that both parties honor the same length of notice.</p>
    
        <h2>2. Refunds Based on Performance</h2>
        <ul>
            <li>If the assigned employee fails to meet the expected performance standards entirely, we will provide a full refund for services rendered.</li>
            <li>In cases where the employee's performance is acceptable but not to the expected standard, we will only charge for the time worked, prorated accordingly. Any remaining balance will be returned to you.</li>
        </ul>
    
        <h2>3. Employee Replacement Option</h2>
        <p>If you're dissatisfied with an employee’s performance, you may request a replacement. In this case, any remaining balance from the current employee will be credited toward the new hire.</p>
    
        <h2>4. Raising Concerns</h2>
        <p>To resolve issues swiftly, we request that you notify us of any concerns as early as possible, ideally within the first week of employment. Delay in communicating dissatisfaction may limit our ability to offer refunds or replacements. If the employee has been with you for a reasonable time without any complaints, the regular termination notice (15 days or the agreed period) will be required to conclude the contract.</p>
    
        <p>At ITcrayons.com, our goal is to ensure your experience with us is positive and productive, and we remain committed to resolving any issues promptly and fairly.</p>
    </div>
</section>
