<header class="header fixed-top">
    <div id="topbar" class="d-none d-lg-flex align-items-center">
      <div class="container d-flex align-items-center">
        <div class="contact-info mr-auto">
          <ul>
            <li><i class="icofont-envelope"></i> <a href="mailto:itcrayons@outlook.com">itcrayons@outlook.com</a></li>
            <li><i class="icofont-phone"></i> <a href="tel:+917617443380">+91 76174 43380</a></li>
            <li><i class="icofont-clock-time icofont-flip-horizontal"></i> Mon-Fri 10am - 6pm</li>
          </ul>
        </div>
        <div class="cta">
          <a routerLink="contact" class="btn btn-primary">Get Started</a>
        </div>
      </div>
    </div>
  
    <nav class="header-menu" [ngClass]="{'headerFixed': headerFixed}">
      <div class="container d-flex align-items-center">
        <h1 class="logo mr-auto">
          <a routerLink="/" class="scrollto">
            <img src="../../../assets/images/logo.svg" width="160px" alt="logo" />
          </a>
        </h1>
        <button class="navbar-toggler" type="button" (click)="toggle()">
          <span class="navbar-toggler-icon"><i class="fa-solid fa-bars"></i></span>
        </button>
        <nav class="nav-menu" [ngStyle]="mobileNav ? {'display': 'block'} : {}">
          <ul class="d-flex">
            <li *ngFor="let link of links" class="has-submenu">
              <a [routerLink]="link.href">{{ link.text }}</a>
              <!-- Submenu -->
              <ul *ngIf="link.submenu" class="submenu">
                <li *ngFor="let sublink of link.submenu" class="has-subsubmenu">
                  <a [routerLink]="sublink.href">{{ sublink.text }}</a>
                  <!-- Sub-submenu -->
                  <ul *ngIf="sublink.submenu" class="subsubmenu">
                    <li *ngFor="let subSubLink of sublink.submenu">
                      <a [routerLink]="subSubLink.href">{{ subSubLink.text }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </nav>
  </header>
  