<section class="blog-deatils">
  <div class="container">
    <div *ngIf="blog" class="blog-detail">
      <h1>{{ blog.title }}</h1>
      <img width="100%" [src]="blog.image" alt="{{ blog.title }} ">
      <p>{{ blog.content }}</p>
    </div>
  </div>
</section>

