import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-business-card-designers',
  templateUrl: './hire-business-card-designers.component.html',
  styleUrls: ['./hire-business-card-designers.component.scss']
})
export class HireBusinessCardDesignersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
