import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { EmailService } from './services/email.service';
import { LoaderComponent } from './loader/loader/loader.component';
import { NotFoundComponent } from './pages/error/not-found.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ItOutsourcingComponent } from './it-outsourcing/it-outsourcing.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { ContentWritingComponent } from './content-writing/content-writing.component';
import { HireDeveloperComponent } from './hire-developer/hire-developer.component';
import { GraphicWebDesignComponent } from './graphic-web-design/graphic-web-design.component';
import { FinanceAccountsComponent } from './finance-accounts/finance-accounts.component';
import { OutsourceToIndiaComponent } from './outsource-to-india/outsource-to-india.component';
import { EmbeddedDevelopmentComponent } from './embedded-development/embedded-development.component';
import { EngineersArchitectsComponent } from './engineers-architects/engineers-architects.component';
import { LegalProccessOutsourcingComponent } from './legal-proccess-outsourcing/legal-proccess-outsourcing.component';
import { MobileAppsDevelopmentComponent } from './mobile-apps-development/mobile-apps-development.component';
import { HireVirtualAssistantComponent } from './hire-virtual-assistant/hire-virtual-assistant.component';
import { MedicalProcessOutsourcingComponent } from './medical-process-outsourcing/medical-process-outsourcing.component';
import { FrontendDevelopersComponent } from './frontend-developers/frontend-developers.component';
import { HireDataScienceExpertComponent } from './hire-data-science-expert/hire-data-science-expert.component';
import { HireMachineLearningExpertsComponent } from './hire-machine-learning-experts/hire-machine-learning-experts.component';
import { HireBackendDevelopersComponent } from './hire-backend-developers/hire-backend-developers.component';
import { HireArtificialIntelligenceSpecialistsComponent } from './hire-artificial-intelligence-specialists/hire-artificial-intelligence-specialists.component';
import { HireBigDataEngineersComponent } from './hire-big-data-engineers/hire-big-data-engineers.component';
import { HireCmsDevelopersComponent } from './hire-cms-developers/hire-cms-developers.component';
import { ItSupportComponent } from './it-support/it-support.component';
import { DatabaseProgrammerComponent } from './database-programmer/database-programmer.component';
import { SoftwareTestingComponent } from './software-testing/software-testing.component';
import { HireSeoExpertsComponent } from './hire-seo-experts/hire-seo-experts.component';
import { HirePpcExpertsComponent } from './hire-ppc-experts/hire-ppc-experts.component';
import { HireCroExpertsComponent } from './hire-cro-experts/hire-cro-experts.component';
import { GoogleAnalyticsExpertsComponent } from './google-analytics-experts/google-analytics-experts.component';
import { OutsourceAdOperationComponent } from './outsource-ad-operation/outsource-ad-operation.component';
import { HireSmmExpertsComponent } from './hire-smm-experts/hire-smm-experts.component';
import { HireContentMarketerComponent } from './hire-content-marketer/hire-content-marketer.component';
import { HireEmailMarketingExpertsComponent } from './hire-email-marketing-experts/hire-email-marketing-experts.component';
import { HireAsoExpertsComponent } from './hire-aso-experts/hire-aso-experts.component';
import { HireVideoMarketingExpertsComponent } from './hire-video-marketing-experts/hire-video-marketing-experts.component';
import { HireArticleWriterComponent } from './hire-article-writer/hire-article-writer.component';
import { HireDedicatedTranscriptionistsComponent } from './hire-dedicated-transcriptionists/hire-dedicated-transcriptionists.component';
import { HirePhpDevelopersComponent } from './hire-php-developers/hire-php-developers.component';
import { HireZohoAppDevelopersComponent } from './hire-zoho-app-developers/hire-zoho-app-developers.component';
import { HireZendDevelopersComponent } from './hire-zend-developers/hire-zend-developers.component';
import { HireAjaxDevelopersComponent } from './hire-ajax-developers/hire-ajax-developers.component';
import { HireDotNetDevelopersComponent } from './hire-dot-net-developers/hire-dot-net-developers.component';
import { HireSalesforceDevelopersComponent } from './hire-salesforce-developers/hire-salesforce-developers.component';
import { HireUmbracoDevelopersComponent } from './hire-umbraco-developers/hire-umbraco-developers.component';
import { HireCPlusPlusDevelopersComponent } from './hire-c-plus-plus-developers/hire-c-plus-plus-developers.component';
import { HireGraphqlDevelopersComponent } from './hire-graphql-developers/hire-graphql-developers.component';
import { HireGolangDevelopersComponent } from './hire-golang-developers/hire-golang-developers.component';
import { HireDjangoDevelopersComponent } from './hire-django-developers/hire-django-developers.component';
import { HireJavaDevelopersComponent } from './hire-java-developers/hire-java-developers.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { FaqsComponent } from './faqs/faqs.component';
import { VideoComponent } from './video/video.component';
import { ArticlesComponent } from './articles/articles.component';
import { BlogComponent } from './blog/blog.component';
import { GuidesComponent } from './guides/guides.component';
import { EbooksComponent } from './ebooks/ebooks.component';
import { AwardsAndRecognitionComponent } from './awards-and-recognition/awards-and-recognition.component';
import { HireScikitLearnDevelopersComponent } from './hire-scikit-learn-developers/hire-scikit-learn-developers.component';
import { HireWoocommerceDevelopersComponent } from './hire-woocommerce-developers/hire-woocommerce-developers.component';
import { HireKotlinDevelopersComponent } from './hire-kotlin-developers/hire-kotlin-developers.component';
import { HirePythonDevelopersComponent } from './hire-python-developers/hire-python-developers.component';
import { HireFlaskDevelopersComponent } from './hire-flask-developers/hire-flask-developers.component';
import { HireTensorflowDevelopersComponent } from './hire-tensorflow-developers/hire-tensorflow-developers.component';
import { HireDrupalDevelopersComponent } from './hire-drupal-developers/hire-drupal-developers.component';
import { HireMagentoDevelopersComponent } from './hire-magento-developers/hire-magento-developers.component';
import { HireWordpressDevelopersComponent } from './hire-wordpress-developers/hire-wordpress-developers.component';
import { HireBlockchainDevelopersComponent } from './hire-blockchain-developers/hire-blockchain-developers.component';
import { HireNftDevelopersComponent } from './hire-nft-developers/hire-nft-developers.component';
import { HireSymfonyDevelopersComponent } from './hire-symfony-developers/hire-symfony-developers.component';
import { HireAngularjsDevelopersComponent } from './hire-angularjs-developers/hire-angularjs-developers.component';
import { HireRDevelopersComponent } from './hire-r-developers/hire-r-developers.component';
import { HireIonicDevelopersComponent } from './hire-ionic-developers/hire-ionic-developers.component';
import { HireNodejsDevelopersComponent } from './hire-nodejs-developers/hire-nodejs-developers.component';
import { HireMeanStackDevelopersComponent } from './hire-mean-stack-developers/hire-mean-stack-developers.component';
import { HireShopifyDevelopersComponent } from './hire-shopify-developers/hire-shopify-developers.component';
import { HireLaravelDevelopersComponent } from './hire-laravel-developers/hire-laravel-developers.component';
import { HireBlazorDevelopersComponent } from './hire-blazor-developers/hire-blazor-developers.component';
import { HireSoftwareDevelopersComponent } from './hire-software-developers/hire-software-developers.component';
import { HireReactjsDevelopersComponent } from './hire-reactjs-developers/hire-reactjs-developers.component';
import { HireUnity3dDeveloperComponent } from './hire-unity3d-developer/hire-unity3d-developer.component';
import { HireHadoopDevelopersComponent } from './hire-hadoop-developers/hire-hadoop-developers.component';
import { HireKubernetesDevelopersComponent } from './hire-kubernetes-developers/hire-kubernetes-developers.component';
import { HireDevopsDevelopersComponent } from './hire-devops-developers/hire-devops-developers.component';
import { HireDedicatedDesignersComponent } from './hire-dedicated-designers/hire-dedicated-designers.component';
import { HireGraphicDesignerComponent } from './hire-graphic-designer/hire-graphic-designer.component';
import { HireWebDesignerionComponent } from './hire-web-designerion/hire-web-designerion.component';
import { HireMultimediaAnimationExpertsComponent } from './hire-multimedia-animation-experts/hire-multimedia-animation-experts.component';
import { HirePhotoshopArtistComponent } from './hire-photoshop-artist/hire-photoshop-artist.component';
import { HireLogoDesignersComponent } from './hire-logo-designers/hire-logo-designers.component';
import { HireIndesignExpertComponent } from './hire-indesign-expert/hire-indesign-expert.component';
import { HireIllustratorExpertComponent } from './hire-illustrator-expert/hire-illustrator-expert.component';
import { HireBusinessCardDesignersComponent } from './hire-business-card-designers/hire-business-card-designers.component';
import { HireAccountantComponent } from './hire-accountant/hire-accountant.component';
import { HireBookkeepersComponent } from './hire-bookkeepers/hire-bookkeepers.component';
import { HireFinancialParaplannersComponent } from './hire-financial-paraplanners/hire-financial-paraplanners.component';
import { HireQuickbooksExpertComponent } from './hire-quickbooks-expert/hire-quickbooks-expert.component';
import { HireAccountsReceivableExpertComponent } from './hire-accounts-receivable-expert/hire-accounts-receivable-expert.component';
import { HireAccountsPayableSpecialistComponent } from './hire-accounts-payable-specialist/hire-accounts-payable-specialist.component';
import { HireFinancialControllerComponent } from './hire-financial-controller/hire-financial-controller.component';
import { HirePayrollExpertsComponent } from './hire-payroll-experts/hire-payroll-experts.component';
import { HireTaxPreparerComponent } from './hire-tax-preparer/hire-tax-preparer.component';
import { HireFinancialAnalystComponent } from './hire-financial-analyst/hire-financial-analyst.component';
import { HireFinanceManagerComponent } from './hire-finance-manager/hire-finance-manager.component';
import { PcbCircuitDesignComponent } from './pcb-circuit-design/pcb-circuit-design.component';
import { EmbeddedArchitectExpertsComponent } from './embedded-architect-experts/embedded-architect-experts.component';
import { SoftwareFirmwareDevelopersComponent } from './software-firmware-developers/software-firmware-developers.component';
import { TestingVerificationComponent } from './testing-verification/testing-verification.component';
import { HireCadDesignerComponent } from './hire-cad-designer/hire-cad-designer.component';
import { HireHvacDesignEngineersComponent } from './hire-hvac-design-engineers/hire-hvac-design-engineers.component';
import { HireMechanicalElectricalPlumbingEngineersComponent } from './hire-mechanical-electrical-plumbing-engineers/hire-mechanical-electrical-plumbing-engineers.component';
import { HireMechanicalDesignEngineersComponent } from './hire-mechanical-design-engineers/hire-mechanical-design-engineers.component';
import { HireStructuralCivilEngineeringComponent } from './hire-structural-civil-engineering/hire-structural-civil-engineering.component';
import { HireElectronicsEngineersComponent } from './hire-electronics-engineers/hire-electronics-engineers.component';
import { HireElectricalEngineerComponent } from './hire-electrical-engineer/hire-electrical-engineer.component';
import { LegalSupportServicesComponent } from './legal-support-services/legal-support-services.component';
import { HireLegalAssistantComponent } from './hire-legal-assistant/hire-legal-assistant.component';
import { HireLegalTranscriptionistComponent } from './hire-legal-transcriptionist/hire-legal-transcriptionist.component';
import { HireParalegalSpecialistComponent } from './hire-paralegal-specialist/hire-paralegal-specialist.component';
import { DocumentReviewServicesComponent } from './document-review-services/document-review-services.component';
import { DocumentDraftingServicesComponent } from './document-drafting-services/document-drafting-services.component';
import { HireIntellectualPropertyExpertComponent } from './hire-intellectual-property-expert/hire-intellectual-property-expert.component';
import { EDiscoveryServicesComponent } from './e-discovery-services/e-discovery-services.component';
import { ImmigrationServicesComponent } from './immigration-services/immigration-services.component';
import { LitigationSupportServicesComponent } from './litigation-support-services/litigation-support-services.component';
import { HireLegalAnalystIndiaComponent } from './hire-legal-analyst-india/hire-legal-analyst-india.component';
import { LegalRecordsManagementClerkComponent } from './legal-records-management-clerk/legal-records-management-clerk.component';
import { HireLegalResearcherComponent } from './hire-legal-researcher/hire-legal-researcher.component';
import { HireFlutterAppDeveloperComponent } from './hire-flutter-app-developer/hire-flutter-app-developer.component';
import { HireSwiftAppDevelopersComponent } from './hire-swift-app-developers/hire-swift-app-developers.component';
import { HireAndroidDeveloperComponent } from './hire-android-developer/hire-android-developer.component';
import { HireFirebaseDevelopersComponent } from './hire-firebase-developers/hire-firebase-developers.component';
import { HireIosAppDeveloperComponent } from './hire-ios-app-developer/hire-ios-app-developer.component';
import { HireCloudComputingDevelopersComponent } from './hire-cloud-computing-developers/hire-cloud-computing-developers.component';
import { HireWindowsMobileAppDevelopersComponent } from './hire-windows-mobile-app-developers/hire-windows-mobile-app-developers.component';
import { HireMobileGameDeveloperComponent } from './hire-mobile-game-developer/hire-mobile-game-developer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { UserAgreementPolicyComponent } from './user-agreement-policy/user-agreement-policy.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    ContactUsComponent,
    LoaderComponent,
    NotFoundComponent,
    CarouselComponent,
    ItOutsourcingComponent,
    DigitalMarketingComponent,
    ContentWritingComponent,
    HireDeveloperComponent,
    GraphicWebDesignComponent,
    FinanceAccountsComponent,
    OutsourceToIndiaComponent,
    EmbeddedDevelopmentComponent,
    EngineersArchitectsComponent,
    LegalProccessOutsourcingComponent,
    MobileAppsDevelopmentComponent,
    HireVirtualAssistantComponent,
    MedicalProcessOutsourcingComponent,
    FrontendDevelopersComponent,
    HireDataScienceExpertComponent,
    HireMachineLearningExpertsComponent,
    HireBackendDevelopersComponent,
    HireArtificialIntelligenceSpecialistsComponent,
    HireBigDataEngineersComponent,
    HireCmsDevelopersComponent,
    ItSupportComponent,
    DatabaseProgrammerComponent,
    SoftwareTestingComponent,
    HireSeoExpertsComponent,
    HirePpcExpertsComponent,
    HireCroExpertsComponent,
    GoogleAnalyticsExpertsComponent,
    OutsourceAdOperationComponent,
    HireSmmExpertsComponent,
    HireContentMarketerComponent,
    HireEmailMarketingExpertsComponent,
    HireAsoExpertsComponent,
    HireVideoMarketingExpertsComponent,
    HireArticleWriterComponent,
    HireDedicatedTranscriptionistsComponent,
    HirePhpDevelopersComponent,
    HireZohoAppDevelopersComponent,
    HireZendDevelopersComponent,
    HireAjaxDevelopersComponent,
    HireDotNetDevelopersComponent,
    HireSalesforceDevelopersComponent,
    HireUmbracoDevelopersComponent,
    HireCPlusPlusDevelopersComponent,
    HireGraphqlDevelopersComponent,
    HireGolangDevelopersComponent,
    HireDjangoDevelopersComponent,
    HireJavaDevelopersComponent,
    OurTeamComponent,
    ImageGalleryComponent,
    TestimonialComponent,
    FaqsComponent,
    VideoComponent,
    ArticlesComponent,
    BlogComponent,
    GuidesComponent,
    EbooksComponent,
    AwardsAndRecognitionComponent,
    HireScikitLearnDevelopersComponent,
    HireWoocommerceDevelopersComponent,
    HireKotlinDevelopersComponent,
    HirePythonDevelopersComponent,
    HireFlaskDevelopersComponent,
    HireTensorflowDevelopersComponent,
    HireDrupalDevelopersComponent,
    HireMagentoDevelopersComponent,
    HireWordpressDevelopersComponent,
    HireBlockchainDevelopersComponent,
    HireNftDevelopersComponent,
    HireSymfonyDevelopersComponent,
    HireAngularjsDevelopersComponent,
    HireRDevelopersComponent,
    HireIonicDevelopersComponent,
    HireNodejsDevelopersComponent,
    HireMeanStackDevelopersComponent,
    HireShopifyDevelopersComponent,
    HireLaravelDevelopersComponent,
    HireBlazorDevelopersComponent,
    HireSoftwareDevelopersComponent,
    HireReactjsDevelopersComponent,
    HireUnity3dDeveloperComponent,
    HireHadoopDevelopersComponent,
    HireKubernetesDevelopersComponent,
    HireDevopsDevelopersComponent,
    HireDedicatedDesignersComponent,
    HireGraphicDesignerComponent,
    HireWebDesignerionComponent,
    HireMultimediaAnimationExpertsComponent,
    HirePhotoshopArtistComponent,
    HireLogoDesignersComponent,
    HireIndesignExpertComponent,
    HireIllustratorExpertComponent,
    HireBusinessCardDesignersComponent,
    HireAccountantComponent,
    HireBookkeepersComponent,
    HireFinancialParaplannersComponent,
    HireQuickbooksExpertComponent,
    HireAccountsReceivableExpertComponent,
    HireAccountsPayableSpecialistComponent,
    HireFinancialControllerComponent,
    HirePayrollExpertsComponent,
    HireTaxPreparerComponent,
    HireFinancialAnalystComponent,
    HireFinanceManagerComponent,
    PcbCircuitDesignComponent,
    EmbeddedArchitectExpertsComponent,
    SoftwareFirmwareDevelopersComponent,
    TestingVerificationComponent,
    HireCadDesignerComponent,
    HireHvacDesignEngineersComponent,
    HireMechanicalElectricalPlumbingEngineersComponent,
    HireMechanicalDesignEngineersComponent,
    HireStructuralCivilEngineeringComponent,
    HireElectronicsEngineersComponent,
    HireElectricalEngineerComponent,
    LegalSupportServicesComponent,
    HireLegalAssistantComponent,
    HireLegalTranscriptionistComponent,
    HireParalegalSpecialistComponent,
    DocumentReviewServicesComponent,
    DocumentDraftingServicesComponent,
    HireIntellectualPropertyExpertComponent,
    EDiscoveryServicesComponent,
    ImmigrationServicesComponent,
    LitigationSupportServicesComponent,
    HireLegalAnalystIndiaComponent,
    LegalRecordsManagementClerkComponent,
    HireLegalResearcherComponent,
    HireFlutterAppDeveloperComponent,
    HireSwiftAppDevelopersComponent,
    HireAndroidDeveloperComponent,
    HireFirebaseDevelopersComponent,
    HireIosAppDeveloperComponent,
    HireCloudComputingDevelopersComponent,
    HireWindowsMobileAppDevelopersComponent,
    HireMobileGameDeveloperComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    CookiesPolicyComponent,
    UserAgreementPolicyComponent,
    BlogDetailsComponent,
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule
  ],
  providers: [EmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
