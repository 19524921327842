<!--Footer Section-->
<footer class="footer">  
    <!--Footer Top-->  
    <div class="footer-top">
        <div class="container px-lg-5">
            <div class="row">
                <div class="col-12 py-4">
                    <div class="row">
                        <div class="col-md-3 mb-md-0 mb-4">
                            <h1 class="logo mr-auto">
                                <a class="scrollto"  href="/">
                                    <img src="../../../assets/images/logo-white.svg" width="160px" alt="logo"/>
                                </a>
                            </h1>
                            <!-- <h3>About us</h3> -->
                           
                            <p> Website Design and Mobile App Development Company  </p>
                            <p>Wavecity, NH-24, Ghaziabad,  Uttar Pradesh 201002</p>
                            <ul class="ftco-footer-social p-0">
                                <li class="ftco-animate fadeInUp ftco-animated">
                                    <a href="https://www.facebook.com/profile.php?id=61550671303110" target="_blank" data-toggle="tooltip"
                                        data-placement="top" title="Twitter" aria-label="Read more about twitter account">
                                        <i class="icofont-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/it_crayons/" target="_blank" data-toggle="tooltip"
                                    data-placement="top" title="Instagram" aria-label="Read more about Instagram account">
                                        <i class="icofont-instagram"></i>
                                    </a>
                                </li>
                                <li class="ftco-animate fadeInUp ftco-animated">
                                    <a href="https://www.linkedin.com/in/it-crayons/" target="_blank" data-toggle="tooltip"
                                        data-placement="top" title="linkedin" aria-label="Read more about linkedin account">
                                        <i class="icofont-linkedin"></i>
                                    </a>
                                </li>
                                <li class="ftco-animate fadeInUp ftco-animated">
                                    <a href="https://x.com/ITCRAYONS" target="_blank" data-toggle="tooltip"
                                        data-placement="top" title="Twitter" aria-label="Read more about Twitter account">
                                        <i class="icofont-twitter"></i>
                                    </a>
                                </li>
                                <li class="ftco-animate fadeInUp ftco-animated">
                                    <a href="https://join.skype.com/invite/uzpT18fWRfWP" target="_blank" data-toggle="tooltip"
                                        data-placement="top" title="Skype" aria-label="Read more about skype account">
                                        <i class="icofont-skype"></i>
                                    </a>
                                </li>
                                <li class="ftco-animate fadeInUp ftco-animated">
                                    <a href="https://www.youtube.com/@ITCrayonshq" target="_blank" data-toggle="tooltip"
                                        data-placement="top" title="Youtube" aria-label="Read more about Youtube account">
                                        <i class="icofont-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        
                        </div>
                        <div class="col-md-9">
                            <div class="row justify-content-center">
                                <div class="col-md-12 col-lg-10">
                                    <div class="row">
                                        <div class="col-md-4 mb-md-0 mb-4">
                                            <h2 class="footer-heading">Company Details</h2>
                                            <ul class="list-unstyled">
                                                <li><a href="/about" aria-label="Learn more about HTML5" class="py-1 d-block">About US</a></li>
                                                <li><a href="/contact" aria-label="Learn more about ReactJS" class="py-1 d-block">Contact Us</a></li>
                                                <li><a href="/blogs" aria-label="Learn more about VueJs" class="py-1 d-block">Blog</a></li>
                                                <!-- <li><a href="#" aria-label="Learn more about Angular" class="py-1 d-block">Awards</a></li> -->
                                                <li><a href="#" aria-label="Learn more about Node.JS" class="py-1 d-block">Sitemap</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4 mb-md-0 mb-4">
                                            <h2 class="footer-heading">Why ITcrayons</h2>
                                            <ul class="list-unstyled">
                                                <li><a href="#" aria-label="Why ITcrayons" class="py-1 d-block">Why ITcrayons</a></li>
                                                <li><a href="#" aria-label="How Does ITcrayons Work" class="py-1 d-block">How Does ITcrayons Work</a></li>
                                                <li><a href="#" aria-label="India-Based Offshore Solutions" class="py-1 d-block">India-Based Offshore Solutions</a></li>
                                                <!-- <li><a href="#" aria-label="Remote Work Solutions" class="py-1 d-block">Remote Work Solutions</a></li> -->
                                                <li><a href="#" aria-label="ITcrayons Advantage" class="py-1 d-block">ITcrayons Advantage</a></li>
                                                <!-- <li><a href="#" aria-label="Ensuring Data Security with IT Experts" class="py-1 d-block">Ensuring Data Security with IT Experts</a></li> -->
                                                <li><a href="#" aria-label="Pricing Insights" class="py-1 d-block">Pricing Insights</a></li>
                                                <li><a href="#" aria-label="Freelancers vs. Tech Experts" class="py-1 d-block">Freelancers vs. Tech Experts</a></li>
                                                <!-- <li><a href="#" aria-label="Outsourcing Drawbacks" class="py-1 d-block">Outsourcing Drawbacks</a></li> -->
                                            </ul>
                                        </div>
                                        <div class="col-md-4 mb-md-0 mb-4">
                                            <h2 class="footer-heading">Policy</h2>
                                            <ul class="list-unstyled">
                                                <li><a href="/privacy-policy" aria-label="Privacy Policy" class="py-1 d-block">Privacy Policy</a></li>
                                                <li><a href="/refund-policy" aria-label="Refund Policy" class="py-1 d-block">Refund Policy</a></li>
                                                <li><a href="/user-agreement" aria-label="User Agreement" class="py-1 d-block">User Agreement</a></li>
                                                <li><a href="/cookies-policy" aria-label="Cookies Policy" class="py-1 d-block">Cookies Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <!--Footer Bottom-->
    <div class="footer-bottom">
        <p class="copyright text-center mb-0">
            Copyright © 2020 - 2021 ITcrayons, All rights reserved.
        </p>
    </div>
</footer>
<!--Footer Section-->