import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderLinks } from '../../interfaces/interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerFixed: boolean = false;
  mobileNav: boolean = false;
  activeSubmenu: number | null = null;
  activeSubSubmenu: number | null = null;



  // Define header links array
  links: HeaderLinks[] = [
    {
      id: 1,
      href: '',
      text: 'Home',
    },
    {
      id: 2,
      href: 'about',
      text: 'About Us',
      submenu: [
        {
          id: 1,
          href: 'our-experts',
          text: 'Our Expert',
        },

        {
          id: 1,
          href: 'our-gallery',
          text: 'Our Gallery',
        },
      ],
    },
    {
      id: 3,
      href: 'services',
      text: 'Services',
      submenu: [
        // {
        //   id: 1,
        //   href: '',
        //   text: 'Tech Outsourcing Solutions',
        // },
        {
          id: 1,
          href: '',
          text: 'Digital Advertising Solutions',
        },
        {
          id: 2,
          href: '',
          text: 'Professional Content Creation',
        },
        {
          id: 3,
          href: '',
          text: 'Hire Expert Coders',
            submenu: [
              {
                id: 1,
                href: '',
                text: 'Hire Web & Graphics Designer',
              }
            ],
        },
        {
          id: 4,
          href: '',
          text: 'Visual and Web Design Solutions',
        },
        // {
        //   id: 6,
        //   href: '',
        //   text: 'Embedded System Solutions',
        // },
        // {
        //   id: 7,
        //   href: '',
        //   text: 'Expert Architects and Engineers',
        // },
        // {
        //   id: 8,
        //   href: '',
        //   text: 'Legal Support Solutions',
        // },
        {
          id: 5,
          href: '',
          text: 'Custom Mobile App Solutions',
        },
        // {
        //   id: 10,
        //   href: '',
        //   text: 'Remote Assistant Hiring',
        // },
        // {
        //   id: 11,
        //   href: '',
        //   text: 'Medical Support Outsourcing',
        // },
      ],
    },
    {
      id: 4,
      href: 'testimonial',
      text: 'Testimonials',
    },
    {
      id: 5,
      href: 'our-experts',
      text: 'Resources',
      submenu: [
        {
          id: 1,
          href: 'blogs',
          text: 'Our Blogs',
        },
        {
          id: 2,
          href: 'faqs',
          text: 'FAQs',
        },
        {
          id: 1,
          href: 'video',
          text: 'Videos',
        },
        // {
        //   id: 2,
        //   href: 'guides',
        //   text: 'Guides',
        // },
        // {
        //   id: 1,
        //   href: 'ebooks',
        //   text: 'Our Ebooks',
        // },
        // {
        //   id: 2,
        //   href: 'awards',
        //   text: 'Our Awards',
        // },
      ],
    },
    {
      id: 6,
      href: 'contact',
      text: 'Contact Us',
    }
  ];

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrolling, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrolling, true);
  }

  // Toggle submenu visibility
  toggleSubmenu(linkId: number): void {
    this.activeSubmenu = this.activeSubmenu === linkId ? null : linkId;
    this.activeSubSubmenu = null; // Close sub-submenus when toggling a new submenu
  }

  // Toggle subsubmenu visibility
  toggleSubSubmenu(sublinkId: number): void {
    this.activeSubSubmenu = this.activeSubSubmenu === sublinkId ? null : sublinkId;
  }

  // Header fixed logic on scroll
  scrolling = (event: Event): void => {
    const scrollingElement = (event.target as Document).scrollingElement;
    const scrollingElementTop = scrollingElement ? scrollingElement.scrollTop : 0;

    this.headerFixed = scrollingElementTop >= 80;
  };




  // Toggle mobile navigation
  toggle(): void {
    this.mobileNav = !this.mobileNav;
  }
}
