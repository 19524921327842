<section class="abs-banner d-flex">
    <h1 class="text-center">About Us</h1>
</section>
<section class="about-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="headtext">

                    <h2 style="color:purple">Welcome to IT Crayons</h2>

                    <p>At IT Crayons, we are passionate about harnessing the power of technology to drive innovation, solve complex problems, and transform businesses. With a rich history of excellence, a commitment to continuous improvement, and a dedicated team of experts, we are your trusted partner in the world of IT.</p>
                    
                    <h5 style="color:purple">Our Mission</h5>
                    
                    <p>Our mission is clear: to empower organizations with cutting-edge technology solutions that streamline operations, boost efficiency, and fuel growth. We believe that technology should be an enabler, and we're here to make that a reality for our clients.</p>
                    
                    
                    <h5 style="color:purple">What Sets Us Apart</h5>
                    
                    <p><b>Expertise:</b> Our team comprises seasoned IT professionals with a diverse range of skills and knowledge. From software development and cybersecurity to cloud computing and data analytics, we have you covered.</p>
                    
                    <p><b>Innovation:</b> We stay ahead of the curve by constantly exploring emerging technologies and adapting them to suit the unique needs of our clients.</p>
                    
                    <p><b>Client-Centric Approach:</b> Your success is our success. We take the time to understand your specific challenges and tailor solutions that fit seamlessly into your organization.</p>
                    
                    
                    <h5 style="color:purple">Our Services</h5>
                    
                    <p><b>IT Consulting:</b> We provide expert guidance to help you make informed technology decisions that align with your business goals.</p>
                    
                    <p><b>Custom Software Development:</b> Tailored software solutions that meet your exact specifications, ensuring efficiency and scalability.</p>
                    
                    <p><b>Cybersecurity:</b> Protecting your digital assets and sensitive information is paramount. We offer comprehensive cybersecurity solutions to keep your organization secure.</p>
                    
                   <p><b>Cloud Solutions:</b> Leverage the power of the cloud to enhance flexibility and scalability while reducing costs.</p> 
                    
                    <p><b>Data Analytics:</b> Turn data into actionable insights to drive informed decision-making and gain a competitive edge.</p>
                    
                    
                    <h5 style="color:purple">Our Team</h5>
                    
                    <p>Meet the driving force behind our success—our dedicated team of IT professionals. With a collective passion for technology and a commitment to excellence, they work tirelessly to deliver outstanding results for our clients.</p>
                    
                    <h5 style="color:purple"> Get in Touch</h5>
                    
                    <p>Ready to explore how technology can transform your business? Contact us today to start a conversation. We're excited to hear about your challenges and help you find the IT solutions that will propel your organization forward.</p>
                    
                   
                    <h5 style="color:purple">Join Us on Our Journey</h5>
                    
                    <p>As we continue to evolve and embrace the ever-changing IT landscape, we invite you to join us on this exciting journey. Together, we can shape the future of technology and achieve remarkable success.</p>
                    
                   <p>Feel free to customize this content to match the specific details, values, and goals of your IT company or department. It should serve as a strong introduction to your organization, highlighting your strengths, services, and commitment to innovation.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="abs-description">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="rs-animation-shape">
                    <div class="images">
                       <img src="assets/images/about-3.png" alt="technology" width="" height="" loading="lazy"> 
                    </div>
                    <div class="middle-image2">
                       <img class="dance3" src="assets/images/effect-3.png" alt="software" width="" height="" loading="lazy"> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="contact-wrap">
                    <div class="sec-title">
                        <h2 class="title">
                            We Are Increasing Business Success With Technology
                        </h2>
                        <div class="desc">
                            As the digital landscape continues to evolve, we invite you to join us in shaping the future of business. Together, we can achieve remarkable success, drive innovation, and set new industry standards.

                            Feel free to adapt this content to align with the specific values, goals, and services of your company. The key is to convey your expertise, commitment to clients' success, and the transformative power of technology in a compelling and informative manner.
                        </div>
                    </div>
                    <div class="btn-part">
                        <a class="btn btn-primary" routerLink="/about">Learn-More</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape-image">
            <img class="top dance" src="assets/images/dotted-5.png" alt="We Are Increasing Business Success With Technology"width="" height="" loading="lazy"/>
        </div>
    </div>
</section>
