import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../../app/blog.service/blog.service.component';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blog: any;

  constructor(private route: ActivatedRoute, private blogService: BlogService) {}

  ngOnInit(): void {
    const blogId = +this.route.snapshot.paramMap.get('id')!;  // Get the ID from the route
    this.blog = this.blogService.getPostById(blogId);  // Get the blog post by ID from the service
  }
}
