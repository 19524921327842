<section class="abs-banner d-flex">
  <h1 class="text-center">FAQ's</h1>
</section>
<section class="faqs-section">
  <div class="container">
    <div class="faqs-container">
      <h1 class="faqs-title">Frequently Asked Questions</h1>
      <div class="faqs-list">
        <div class="faq-item" *ngFor="let faq of faqs">
          <div class="faq-question" (click)="toggleAnswer(faq)">
            <h3>{{ faq.question }}</h3>
            <span class="arrow" [ngClass]="{'arrow-down': faq.open, 'arrow-up': !faq.open}"></span>
          </div>
          <div class="faq-answer" *ngIf="faq.open">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  