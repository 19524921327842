import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outsource-to-india',
  templateUrl: './outsource-to-india.component.html',
  styleUrls: ['./outsource-to-india.component.scss']
})
export class OutsourceToIndiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
