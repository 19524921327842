import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-php-developers',
  templateUrl: './hire-php-developers.component.html',
  styleUrls: ['./hire-php-developers.component.scss']
})
export class HirePhpDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
