import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-video-marketing-experts',
  templateUrl: './hire-video-marketing-experts.component.html',
  styleUrls: ['./hire-video-marketing-experts.component.scss']
})
export class HireVideoMarketingExpertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
