import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-review-services',
  templateUrl: './document-review-services.component.html',
  styleUrls: ['./document-review-services.component.scss']
})
export class DocumentReviewServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
