import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engineers-architects',
  templateUrl: './engineers-architects.component.html',
  styleUrls: ['./engineers-architects.component.scss']
})
export class EngineersArchitectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
