import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../app/blog.service/blog.service.component';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  posts: any[] = [];

  constructor(private blogService: BlogService, private router: Router) {}

  ngOnInit(): void {
    this.posts = this.blogService.getAllPosts();  // Get posts from the service
  }

  readMore(id: number) {
    this.router.navigate(['/blog', id]);  // Navigate to the blog details page
  }



}
