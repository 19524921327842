import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-flutter-app-developer',
  templateUrl: './hire-flutter-app-developer.component.html',
  styleUrls: ['./hire-flutter-app-developer.component.scss']
})
export class HireFlutterAppDeveloperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
