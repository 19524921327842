import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-quickbooks-expert',
  templateUrl: './hire-quickbooks-expert.component.html',
  styleUrls: ['./hire-quickbooks-expert.component.scss']
})
export class HireQuickbooksExpertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
