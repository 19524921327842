import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { EmailService } from '../../services/email.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {


  contactForm: FormGroup;

  constructor(private fb: FormBuilder,private emailservice: EmailService, private loaderservice: LoaderService) { 
    this.contactForm = fb.group({
      'name': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      'phone': ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      'message': ['', Validators.required]  
    });
  }

  get f(){
    return this.contactForm.controls;
  }

  onSubmit():void {
   // alert(this.contactForm.valid);
    this.loaderservice.isLoading.next(true);
    this.emailservice.sendMessage(this.contactForm.value).subscribe(
      data => {
        console.log(data);
        this.loaderservice.isLoading.next(false);
      },
      err => {
        console.log(err); 
        this.loaderservice.isLoading.next(false);
      }
  
  )}

 
  mainBannersSlides = [
    {
      id: 1, 
      img: "assets/images/slide1.jpg",
      caption: 'Slide 2 Caption'
    },
    {
      id: 2, 
      img: "assets/images/slide2.jpg",
      caption: 'Slide 1 Caption'
    },
    {
      id: 3, 
      img: "assets/images/slide3.jpg",
      caption: 'Slide 2 Caption'
    }
	 ];


   mainBanners = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    autoplay: true,
    navSpeed: 300,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
}
