import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-golang-developers',
  templateUrl: './hire-golang-developers.component.html',
  styleUrls: ['./hire-golang-developers.component.scss']
})
export class HireGolangDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
