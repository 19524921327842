import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BlogService {

  posts = [
    {
      id: 1,
      title: 'The Future of Web Development',
      date: new Date('2024-09-10'),
      excerpt: 'The Future of Web Development: Trends, Technologies, and Opportunities',
      content: 'The future of web development is evolving rapidly with several key trends shaping the industry. Progressive Web Apps (PWAs) are transforming user experiences by offering app-like functionality directly in the browser. Artificial Intelligence (AI) and Machine Learning (ML) are enhancing personalization and automation on websites. Voice search optimization is becoming crucial as more users rely on voice assistants. WebAssembly (Wasm) is revolutionizing performance by allowing near-native speed in web applications. Additionally, cybersecurity, low-code platforms, augmented reality (AR), virtual reality (VR), and ethical design are influencing the future. Developers who embrace these trends will lead in creating faster, smarter, and more secure web experiences.This is the full content of The Future of Web Development...',
      image: 'assets/images/web-development.jpg'
    },
    {
      id: 2,
      title: 'Understanding Angular',
      date: new Date('2024-09-15'),
      excerpt: 'A deep dive into Angular and how it simplifies web application development.',
      content: 'This is the full content of Understanding Angular...',
      image: 'assets/images/angular.jpg'
    },
    {
      id: 3,
      title: 'Designing User-Friendly Interfaces',
      date: new Date('2024-09-20'),
      excerpt: 'Learn how to design interfaces that provide a great user experience.',
      content: 'This is the full content of Designing User-Friendly Interfaces...',
      image: 'assets/images/ui-design.jpg'
    }
  ];

  constructor() {}

  getAllPosts() {
    return this.posts;
  }

  getPostById(id: number) {
    return this.posts.find(post => post.id === id);
  }
}
