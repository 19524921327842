import { Component } from '@angular/core';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent  {

  constructor() { }

   // Array to hold the image URLs
   images = [
    'https://uicookies.com/wp-content/uploads/2018/03/travelix-free-travel-website-templates.jpg',
    'https://www.bootstrapdash.com/wp-content/uploads/2020/03/14600889-c02b-4cf0-80fc-ec616da06366-1.png',
    'https://uicookies.com/wp-content/uploads/2018/03/travelix-free-travel-website-templates.jpg',
    'https://www.bootstrapdash.com/wp-content/uploads/2020/03/14600889-c02b-4cf0-80fc-ec616da06366-1.png',
  ];

  // Function to display image in full view (for Lightbox or fullscreen mode)
  selectedImage: string = '';
  
  openImage(image: string) {
    this.selectedImage = image;
  }

  closeImage() {
    this.selectedImage = '';
  }

}
