<!---About Banner--->
<section class="abs-banner d-flex">
    <h1 class="text-center">Our Services</h1>
</section>
<!---/.About Banner--->
<!---Services--->
<section class="services-wrap">
    <div class="container">
        <div class="row">
            <div class="col-sm-9 m-auto">
                <h2>Mobile Apps, eCommerce, Digital and Cloud Solutions</h2>
                <p>We offer a comprehensive range of IT services that tackle complex business challenges effectively
                    and enable real-time decision-making.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>Magento eCommerce</span>
                        <img src="assets/images/cart.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>Magento eCommerce Development</span>
                        </li>
                        <li>
                            <span>Magento eCommerce Consulting</span>
                        </li>
                        <li>
                            <span>Migration to Magento 2 and Magento EE</span>
                        </li>
                        <li>
                            <span>Magento Support & Maintenance</span>
                        </li>
                        <li>
                            <span>Magento Integrations</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>Mobile App Development</span>
                        <img src="assets/images/mobility.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>iOS & Android Mobile App Development</span>
                        </li>
                        <li>
                            <span>OutSystems Low-Code App Development</span>
                        </li>
                        <li>
                            <span>Cross Platform Mobile App Development</span>
                        </li>
                        <li>
                            <span>Mobile UI/UX Design & Prototype</span>
                        </li>
                        <li>
                            <span>IoT Mobile App Development</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>Enterprise Applications</span>
                        <img src="assets/images/cart.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>Salesforce CRM Implementation</span>
                        </li>
                        <li>
                            <span>Microsoft SharePoint Development</span>
                        </li>
                        <li>
                            <span>Bespoke Enterprise Applications</span>
                        </li>
                        <li>
                            <span>AWS, Azure Cloud Consulting</span>
                        </li>
                        <li>
                            <span>Application Support & Maintenance</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>Product Development</span>
                        <img src="assets/images/Product-Development_0.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>Outsourced Product Development</span>
                        </li>
                        <li>
                            <span>Product Strategy and Consulting</span>
                        </li>
                        <li>
                            <span>Product Conceptualization and UX design</span>
                        </li>
                        <li>
                            <span>Product Development and Enhancement</span>
                        </li>
                        <li>
                            <span>Product Maintenance Support and Scaling</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>QA & Testing Services</span>
                        <img src="assets/images/qa-testing.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>Test Advisory and Transformation</span>
                        </li>
                        <li>
                            <span>Agile & DevOps Testing</span>
                        </li>
                        <li>
                            <span>Functional / Non-Functional Testing</span>
                        </li>
                        <li>
                            <span>Automation Testing</span>
                        </li>
                        <li>
                            <span>Mobile Application Testing</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-4">
                <div class="service-list">
                    <h3>
                        <span>Web & CMS Development</span>
                        <img src="assets/images/Web-development.png" alt="" />
                    </h3>
                    <ul class="lists">
                        <li>
                            <span>Enterprise Drupal/Acquia Development</span>
                        </li>
                        <li>
                            <span>Enterprise WordPress Development</span>
                        </li>
                        <li>
                            <span>Custom Web Application Development</span>
                        </li>
                        <li>
                            <span>UI/UX Design & Development</span>
                        </li>
                        <li>
                            <span>Mobile Responsive Development</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!------>
<section class="contact-sec">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-8">
                <h3>Need help? Let's Talk.</h3>
                <p>Whatever business problem you have, we have the Back-End solutions to help.</p>
            </div>
            <div class="col-sm-4 text-right">
                <div class="btn-wrapper">
                    <a routerLink="/contact" class="btn btn-secondary">Contact Us</a>
                </div>
               
            </div>
        </div>
    </div>
</section>
<!---/.--->