import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-mean-stack-developers',
  templateUrl: './hire-mean-stack-developers.component.html',
  styleUrls: ['./hire-mean-stack-developers.component.scss']
})
export class HireMeanStackDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
