import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-indesign-expert',
  templateUrl: './hire-indesign-expert.component.html',
  styleUrls: ['./hire-indesign-expert.component.scss']
})
export class HireIndesignExpertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
