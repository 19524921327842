<section class="abs-banner d-flex">
  <h1 class="text-center">Our Team</h1>
</section>
<section class="our-team">
    <div class="container">
        <div class="experts-container">
            <h1 class="experts-title">Meet Our Experts</h1>
            <div class="experts-grid">
              <div class="expert-card" *ngFor="let expert of experts">
                <img class="expert-photo" [src]="expert.photo" alt="{{ expert.name }}" />
                <h3 class="expert-name">{{ expert.name }}</h3>
                <p class="expert-title">{{ expert.title }}</p>
                <p class="expert-bio">{{ expert.bio }}</p>
              </div>
            </div>
          </div>
    </div>
</section>
