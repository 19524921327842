import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-ionic-developers',
  templateUrl: './hire-ionic-developers.component.html',
  styleUrls: ['./hire-ionic-developers.component.scss']
})
export class HireIonicDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
