import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-dedicated-transcriptionists',
  templateUrl: './hire-dedicated-transcriptionists.component.html',
  styleUrls: ['./hire-dedicated-transcriptionists.component.scss']
})
export class HireDedicatedTranscriptionistsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
