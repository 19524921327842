import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-payroll-experts',
  templateUrl: './hire-payroll-experts.component.html',
  styleUrls: ['./hire-payroll-experts.component.scss']
})
export class HirePayrollExpertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
