<section class="abs-banner d-flex">
    <h1 class="text-center">User Agreement</h1>
</section>
<section class="user-agreement-section">
<div class="container">
    <h1>ITcrayons Terms and Conditions for Users</h1>

    <p>The following terms and conditions define the operational guidelines for the users of ITcrayons. ITcrayons is a leading outsourcing services company based in India with branches in the UK and the US. By using this website, users agree to abide by these terms and conditions. Users who do not agree with these terms should refrain from using the site.</p>

    <p>The information provided in this statement may be modified with or without prior notice to its users. ITcrayons may change, append, annul, and amend information related to its services provided on its website, linked web pages, or related documents. It may also update website content from time to time without notifying users. Any modifications to these terms and conditions will be incorporated into this agreement.</p>

    <h2>Copyright Issues</h2>

    <p>ITcrayons and ITcrayons.com refer to the same entity, which is ITcrayons Private Limited. The company ensures that all content posted on its site is regulated by intellectual property laws and copyright policies. The company also respects the content regulation policies of other websites.</p>

    <p>All contents of the website, including but not limited to text, related documents, audio, video, icons, buttons, and images are protected by copyright. Users are not permitted to duplicate, transmit, publish, alter, sell, or distribute the contents of the website, its linked pages, and/or related documents without prior written permission from ITcrayons. Users may view, download, and print copies of the content for non-commercial and personal use only.</p>

    <p>Unauthorized use of the website content may be treated as a violation of rules and may lead to penalties or legal action.</p>

    <p>The company respects users' privacy and personal information and does not seek to accumulate user data through its website. However, when using the "Get Started" interface, the company may receive information from users.</p>

    <p>Any information provided to ITcrayons through its website will be considered non-proprietary, and the company may use, reproduce, modify, and distribute these materials without prior consent from the original owners. The website may acknowledge the original owners' names if permission is granted to use their names.</p>

    <p>Users are allowed to post their queries, comments, suggestions, and complaints through the feedback interface. It is the responsibility of users to ensure that content posted through this interface is not malicious, obscene, or defamatory. Users posting such content agree to indemnify ITcrayons, and any incidental expenses arising from such content will be borne by the respective users.</p>

    <p>The website may be linked to third-party sites, including social networking sites, which have their own user terms and conditions. ITcrayons is not responsible for the content, services, or products offered by these third-party sites and will not be accountable for any inaccuracies or breaches of privacy practices on these linked sites.</p>

    <h2>Disclaimer</h2>

    <p>Although ITcrayons strives to provide accurate content, it does not guarantee the accuracy, authenticity, or completeness of the information available on its website. The content may contain technical, conceptual, grammatical, and typographical errors.</p>

    <p>The content on the website is provided on an "AS IS" and "AS AVAILABLE" basis, without any warranty. ITcrayons also does not warrant the content available on its linked sites.</p>

    <p>Viruses, Trojans, and other malicious software may affect the website or its linked pages through third-party sites or other means. ITcrayons will not be responsible for such issues and does not guarantee the removal of these malicious entities. However, it will strive to maintain an error-free site.</p>

    <h2>Limited Liability</h2>

    <p>ITcrayons will not be liable for any damages resulting from the use of its website or any of the services provided on its site. The company will also not be responsible for damages resulting from the use of linked websites. ITcrayons will not entertain any claims for damages, including those based on third-party claims.</p>
</div>
</section>
