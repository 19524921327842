import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-outsourcing',
  templateUrl: './it-outsourcing.component.html',
  styleUrls: ['./it-outsourcing.component.scss']
})
export class ItOutsourcingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
