import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-unity3d-developer',
  templateUrl: './hire-unity3d-developer.component.html',
  styleUrls: ['./hire-unity3d-developer.component.scss']
})
export class HireUnity3dDeveloperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
