import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-nodejs-developers',
  templateUrl: './hire-nodejs-developers.component.html',
  styleUrls: ['./hire-nodejs-developers.component.scss']
})
export class HireNodejsDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
