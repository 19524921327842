import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-legal-transcriptionist',
  templateUrl: './hire-legal-transcriptionist.component.html',
  styleUrls: ['./hire-legal-transcriptionist.component.scss']
})
export class HireLegalTranscriptionistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
