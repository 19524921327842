import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  constructor() { }

  faqs = [
    {
      question: 'What is the difference between website design and development?',
      answer: 'Website design focuses on the visual aspects like layout, colors, and typography, while development involves coding and making the site functional using programming languages.',
      open: false
    },
    {
      question: ' How long does it take to design and develop a website?',
      answer: 'The timeline varies based on complexity, typically ranging from 4 to 8 weeks for a standard website. Custom projects may take longer.',
      open: false
    },
    {
      question: 'How much does a website or mobile app cost?',
      answer: 'Costs depend on features, design complexity, and development time. Websites can range from $2,000 to $50,000+, while mobile apps can start from $10,000 upwards.',
      open: false
    },
    {
      question: 'What is the difference between a native app and a web app?',
      answer: 'Native apps are developed for specific platforms (iOS or Android), while web apps run in browsers and are accessible across devices without platform restrictions.',
      open: false
    },
    {
      question: 'What platforms should my mobile app be built for?',
      answer: 'It depends on your target audience. Generally, iOS and Android are the most common platforms. You can opt for both or start with one and expand later.',
      open: false
    },
    {
      question: 'What is responsive web design, and why is it important?',
      answer: 'Responsive web design ensures that your website looks good and functions properly across devices of all sizes, from desktops to smartphones, improving user experience.',
      open: false
    },
    {
      question: 'Can I update the content of my website after it’s launched?',
      answer: 'Yes, most modern websites are built using content management systems (CMS) like WordPress, which allow easy updates to content without technical skills.',
      open: false
    },
    {
      question: 'What’s the difference between UX and UI design?',
      answer: 'UX (User Experience) focuses on how a user interacts with a website or app, while UI (User Interface) design focuses on the visual layout, ensuring it’s appealing and functional.',
      open: false
    },
    {
      question: 'Do I need a custom website or can I use a template?',
      answer: 'A template is more affordable and faster, but it may not be unique. A custom website is tailored to your specific needs and branding but can be more expensive and time-consuming.',
      open: false
    },
    {
      question: 'What is an MVP (Minimum Viable Product) in mobile app development?',
      answer: 'An MVP is a simplified version of your app with core features that allow you to launch quickly, gather user feedback, and improve the app before a full-scale launch.',
      open: false
    },
    {
      question: 'How do I maintain my website or mobile app after launch?',
      answer: 'Maintenance includes updates, security checks, backups, and performance optimizations. Regular maintenance ensures that your platform runs smoothly and stays secure.',
      open: false
    },
    {
      question: 'What is the importance of SEO in website development?',
      answer: 'SEO (Search Engine Optimization) helps your website rank higher in search engines, making it easier for potential customers to find you online, which boosts traffic and conversions.',
      open: false
    },
    {
      question: 'How secure will my website or mobile app be?',
      answer: 'Security is a key focus in both web and mobile development. Techniques like SSL, encryption, and regular updates help protect against vulnerabilities and data breaches.',
      open: false
    },
    {
      question: 'Can you integrate third-party services into my website or app?',
      answer: 'Yes, many third-party services like payment gateways, email marketing platforms, and CRMs can be integrated into your website or app for added functionality.',
      open: false
    },
    {
      question: 'How do you ensure that my website or app will work across different browsers and devices?',
      answer: 'We use cross-browser testing and responsive design techniques to ensure compatibility across major browsers and devices.',
      open: false
    },
    {
      question: 'What is the process for developing a mobile app?',
      answer: 'The process involves several stages: ideation, wireframing, UI/UX design, development, testing, and launch. Post-launch maintenance and updates are also crucial.',
      open: false
    },
    {
      question: 'What’s the difference between front-end and back-end development?',
      answer: 'Front-end development handles the visual and interactive aspects of a website or app (client-side), while back-end development manages server-side operations like databases and user authentication.',
      open: false
    },
    {
      question: 'What are wireframes and prototypes?',
      answer: 'Wireframes are basic sketches of your website or app layout, and prototypes are more advanced, clickable models that simulate how the final product will function.',
      open: false
    },
    {
      question: 'Will my website or app be scalable as my business grows?',
      answer: 'Yes, we build scalable solutions to handle increased traffic and data as your business expands. Scalable systems are designed with future growth in mind.',
      open: false
    },
    {
      question: 'How do I track the performance of my website or mobile app?',
      answer: 'Tools like Google Analytics for websites and Firebase for apps allow you to monitor traffic, user behavior, and performance to improve user engagement and conversions.',
      open: false
    },
  ];
  toggleAnswer(faq) {
    faq.open = !faq.open;
  }
  ngOnInit(): void {
  }

}
