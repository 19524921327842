import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-financial-paraplanners',
  templateUrl: './hire-financial-paraplanners.component.html',
  styleUrls: ['./hire-financial-paraplanners.component.scss']
})
export class HireFinancialParaplannersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
