<section class="abs-banner d-flex">
    <h1 class="text-center">Privacy Policy</h1>
</section>
<section class="privacy-section">
    <div class="container">
        <h1>Privacy Policy</h1>
        <div class="section">
            <h2>1. Introduction</h2>
            <p>1.1 We are committed to safeguarding the privacy of our website visitors and service users.</p>
            <p>1.2 This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.</p>
            <p>1.3 We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</p>
            <p>1.4 Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the publication of your information. You can access the privacy controls by sending us an email at itcrayonshq&#64;gmail.com.</p>
            <p>1.5 In this policy, “we”, “us” and “our” refer to ITcrayons. For more information about us, see Section 19.</p>
        </div>
    
        <div class="section">
            <h2>2. How we use your personal data</h2>
            <p>2.1 In this Section 2 we have set out:</p>
            <ul>
                <li>(a) the general categories of personal data that we may process;</li>
                <li>(b) in the case of personal data that we did not obtain directly from you, the source and specific categories of that data;</li>
                <li>(c) the purposes for which we may process personal data; and</li>
                <li>(d) the legal bases of the processing.</li>
            </ul>
    
            <p>2.2 We may process data about your use of our website and services (“usage data”). The usage data may include your IP address, geographical location, browser type and version, operating system, URL, referral source, length of visit, page views, session replays, heatmaps and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is consent or our legitimate interests, namely monitoring and improving our website and services.</p>
    
            <p>2.3 We may process your account data (“account data”). The account data may include your name, email address, contact number, company information, information provided by third parties. The source of the account data is you, your employer, or a third party. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, relating to credit score, background verification, maintaining backups of our databases, and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business including its operations in an efficient manner, or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.4 We may process your information included in your personal profile on our website (“profile data”). The profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, interests and hobbies, educational details, company information, billing information, bank account details, social media profile, and employment details. The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business, or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.5 We may process your personal data that is provided in the course of the use of our services (“service data”). The source of the service data is you or your employer. The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining backups of our databases, and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business, or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.6 We may process information that you post for publication on our website or through our services (“publication data”). The publication data may be processed for the purposes of enabling such publication and administering our website and services. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business, or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.7 We may process information contained in any enquiry you submit to us via website forms, email, telephone call, chat, Skype, and any other communication mode regarding services (“enquiry data”). The enquiry data may be processed for the purposes of offering, marketing, and selling relevant services to you. The legal basis for this processing is consent or our legitimate interests, namely the provision of services, or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.8 We may process information relating to our customer relationships, including customer contact information (“customer relationship data”). The customer relationship data may include your name, your employer, your job title or role, your contact details, your company information, billing and bank account information, correspondence information, your company’s credit information, and information contained in communications between us and you or your employer. The source of the customer relationship data is you, your employer, or a third party. The customer relationship data may be processed for the purposes of managing our relationships with customers, communicating with customers, keeping records of those communications, and promoting our products and services to customers. The legal basis for this processing is consent or our legitimate interests, namely the proper management of our customer relationships.</p>
    
            <p>2.9 We may process information relating to billing and transactions, including purchases of services, that you enter into with us and/or through our website (“billing data”). The billing data may include your contact details, bank account details, billing details, contract details, and transaction details. The billing data may be processed for the purpose of supplying the purchased services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract, and our legitimate interests, namely the proper administration of our website and business.</p>
    
            <p>2.10 We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters and/or downloadable content (“notification data”). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters and/or downloadable content. The legal basis for this processing is consent or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>
    
            <p>2.11 We may process information contained in or relating to any communication that you send to us (“correspondence data”). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms, email, telephone call, chat, Skype, and any other communication mode. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</p>
    
            <p>2.12 We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights, and the legal rights of others.</p>
    
            <p>2.13 We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
    
            <p>2.14 In addition to the specific purposes for which we may process your personal data set out in this Section 2, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or to protect your vital interests or the vital interests of another natural person.</p>
    
            <p>2.15 Please do not supply any other person’s personal data to us, unless we prompt you to do so.</p>
        </div>
    
        <div class="section">
            <h2>3. Providing your personal data to others</h2>
            <p>3.1 We may disclose your personal data to our group companies (subsidiaries, holding company, and its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy. For information about our group, contact us at itcrayonshq&#64;gmail.com.</p>
            <p>3.2 We may disclose your personal data to our employees or prospective employees, as necessary for the purposes of performing their roles or positions.</p>
            <p>3.3 We may disclose your personal data to our insurers, professional advisers, and agents, as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or establishing, exercising, or defending legal claims.</p>
            <p>3.4 We may disclose your company information to our suppliers or subcontractors as necessary for the performance of contracts between us and them, including for credit risk management.</p>
            <p>3.5 Financial transactions relating to our website and services are handled by our payment services providers, PayPal and Stripe. We will share transaction data with our payment services providers only to the extent necessary for processing your payments, refunding such payments, and dealing with complaints and queries related to such payments and refunds. See the PayPal Privacy Policy at <a href="https://www.paypal.com/uk/webapps/mpp/ua/privacy-prev">www.paypal.com/uk/webapps/mpp/ua/privacy-prev</a> and the Stripe Privacy Policy at <a href="https://stripe.com/us/privacy">www.stripe.com/us/privacy</a>.</p>
            <p>3.6 We may disclose enquiry data to third-party service providers for the purpose of offering, marketing, and selling relevant goods or services. Each third party will act as a data controller and will provide a separate privacy policy.</p>
            <p>3.7 We may disclose your personal data where required to do so by law, in connection with any legal proceedings or prospective legal proceedings, or to protect our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</p>
            <p>3.8 Your personal data that you publish on our website or through our services may be available, via the internet, around the world. We cannot prevent its use or misuse by others.</p>
        </div>
        <div class="section">
            <h2>4. Retaining and Deleting Personal Data</h2>
            <p>4.1 This Section outlines our data retention policies and procedures, which are designed to ensure compliance with legal obligations regarding the retention and deletion of personal data.</p>
            <p>4.2 Personal data that we process for any purpose will not be retained longer than necessary for that purpose.</p>
            <p>4.3 We will retain your personal data as follows:</p>
            <ul>
                <li>[“publication data”, “enquiry data”, “billing data”, “notification data”] will be retained for a minimum period of [6 months] from [contact date or agreement termination date, whichever is applicable], and for a maximum period of [7 years].</li>
            </ul>
            <p>4.4 In some instances, we may not be able to specify in advance the retention periods for your personal data. In such cases, the retention period will be determined based on:</p>
            <ul>
                <li>the retention period for [“customer relationship data”, “service data”] based on [customer consent].</li>
            </ul>
            <p>4.5 Despite other provisions in this Section, we may retain your personal data if necessary to comply with a legal obligation or to protect your vital interests or those of another individual.</p>
        </div>
    
        <div class="section">
            <h2>5. Security of Personal Data</h2>
            <p>5.1 We will implement appropriate technical and organizational measures to secure your personal data and prevent its loss, misuse, or alteration.</p>
            <p>5.2 We will store [all your personal data] on [secure servers, personal computers, mobile devices, and in secure manual record-keeping systems].</p>
            <p>5.3 Data related to [your inquiries and financial transactions] transmitted from your web browser to our web server, or from our web server to your web browser, will be protected with encryption technology.</p>
            <p>5.4 Please be aware that transmitting unencrypted or inadequately encrypted data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
            <p>5.5 You should ensure your password is not easily guessable by others, whether human or automated. You are responsible for keeping your website access password confidential, and we will not request your password (except when you log in).</p>
        </div>
    
        <div class="section">
            <h2>6. Amendments</h2>
            <p>6.1 We may update this policy from time to time by posting a new version on our website.</p>
            <p>6.2 You should review this page occasionally to ensure you are satisfied with any changes.</p>
            <p>6.3 We [may] notify you of [changes] OR [significant changes] to this policy [by email or through the private messaging system on our website].</p>
        </div>
    
        <div class="section">
            <h2>7. Your Rights</h2>
            <p>7.1 This Section summarizes the rights you have under data protection laws. For a full explanation, you should consult the relevant laws and regulatory guidance.</p>
            <p>7.2 Your principal rights under data protection law include:</p>
            <ul>
                <li>the right to access;</li>
                <li>the right to rectification;</li>
                <li>the right to erasure;</li>
                <li>the right to restrict processing;</li>
                <li>the right to object to processing;</li>
                <li>the right to data portability;</li>
                <li>the right to complain to a supervisory authority; and</li>
                <li>the right to withdraw consent.</li>
            </ul>
            <p>7.3 You have the right to confirmation as to whether we process your personal data, and where we do, access to it along with additional information. We will provide a copy of your personal data free of charge, though additional copies may incur a reasonable fee. You can request your personal data by emailing us at itcrayonshq&#64;gmail.com.</p>
            <p>7.4 You have the right to have inaccurate personal data corrected and to have incomplete personal data completed, considering the purposes of processing.</p>
            <p>7.5 In certain situations, you may request the erasure of your personal data without undue delay, including if the data is no longer needed, you withdraw consent, you object to processing, the data is unlawfully processed, or if processing is for direct marketing purposes. Exceptions to the right to erasure include where processing is necessary for freedom of expression, legal compliance, or legal claims.</p>
            <p>7.6 You may have the right to restrict the processing of your personal data in certain circumstances, such as when contesting its accuracy, if processing is unlawful but you oppose erasure, if we no longer need the data but you require it for legal claims, or if you have objected to processing pending verification. Restricted data may only be processed with your consent or for legal claims, protecting others' rights, or for significant public interest.</p>
            <p>7.7 You can object to processing based on your particular situation, particularly if the processing is based on public interest or legitimate interests. If you object, we will cease processing unless we can show compelling legitimate grounds that override your interests or for legal claims.</p>
            <p>7.8 You may object to processing for direct marketing purposes, including profiling for marketing. If you do, we will stop processing your data for these purposes.</p>
            <p>7.9 You can object to processing for scientific or historical research purposes or statistical purposes if it relates to your situation, unless necessary for a task in the public interest.</p>
            <p>7.10 If the legal basis for processing is consent or necessary for contract performance, and processing is automated, you have the right to receive your data in a structured, commonly used, and machine-readable format. This right does not apply if it would adversely affect others' rights and freedoms.</p>
            <p>7.11 You may exercise your rights by written notice to us at itcrayonshq&#64;gmail.com, in addition to other specified methods.</p>
            <p>7.12 If you believe our processing infringes data protection laws, you have the right to lodge a complaint with a supervisory authority in your EU member state of residence, work, or where the infringement occurred.</p>
            <p>7.13 If processing is based on consent, you may withdraw it at any time. This will not affect the legality of processing before withdrawal.</p>
        </div>
        <div class="section">
            <h2>8. Third Party Websites</h2>
            <p>8.1 Our website may include hyperlinks to third party websites and information about them.</p>
            <p>8.2 We do not have control over and are not responsible for the privacy policies and practices of third parties.</p>
        </div>
    
        <div class="section">
            <h2>9. Updating Information</h2>
            <p>9.1 If the personal information we hold about you needs to be corrected or updated, please notify us. Send an email requesting the change to itcrayonshq&#64;gmail.com.</p>
        </div>
    
        <div class="section">
            <h2>10. Acting as a Data Processor</h2>
            <p>10.1 For [your projects that may be carried out by our employees on your instructions], we do not act as a data controller but may act as a data processor as per the contract between us.</p>
            <p>10.2 When acting as a data processor, this policy does not apply. Our obligations as a data processor are outlined in the contract between us and the data controller.</p>
        </div>
    
        <div class="section">
            <h2>11. About Cookies</h2>
            <p>11.1 A cookie is a file containing an identifier (a string of letters and numbers) sent by a web server to a web browser, which is stored by the browser. The identifier is sent back to the server with each page request.</p>
            <p>11.2 Cookies can be “persistent” or “session” cookies: a persistent cookie remains valid until its expiry date, unless deleted by the user; a session cookie expires when the browser is closed.</p>
            <p>11.3 Cookies usually do not contain personal information, but any personal data we hold about you may be linked to cookie information.</p>
        </div>
    
        <div class="section">
            <h2>12. Cookies We Use</h2>
            <p>12.1 For more details on our cookie use, please refer to our cookie policy at [https://www.ITcrayons.com/cookies-policy].</p>
        </div>
    
        <div class="section">
            <h2>13. Managing Cookies</h2>
            <p>13.1 Most browsers allow you to refuse cookies and delete them. Methods vary by browser, but you can find up-to-date information on blocking and deleting cookies here:</p>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a></li>
                <li><a href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
                <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                <li><a href="https://support.apple.com/kb/PH21411">Safari</a></li>
                <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Edge</a></li>
            </ul>
            <p>13.2 Blocking all cookies may negatively impact website usability.</p>
            <p>13.3 If you block cookies, some features on our website may not be available.</p>
            <p>13.4 For more information on our cookie policy, visit [https://www.ITcrayons.com/cookie-policy].</p>
        </div>
    
        <div class="section">
            <h2>14. Our Details</h2>
            <p>14.1 This website is operated by [ITcrayons Limited].</p>
            <p>14.2 We are registered in [Country], with our registered office at [Address].</p>
            <p>14.3 You can contact us:</p>
            <ul>
                <li>By post, to [the postal address provided above];</li>
                <li>Using our website contact form;</li>
                <li>By telephone, at [the contact number listed on our website]; or</li>
                <li>By email, at [the email address listed on our website].</li>
            </ul>
        </div>
    
        <div class="section">
            <h2>15. Data Protection Officer</h2>
            <p>15.1 Our data protection officer can be reached at: itcrayonshq&#64;gmail.com.</p>
        </div>
    </div>
</section>
