import { Component, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements AfterViewInit {

  testimonials = [
    {
      text: 'This is the best service I have ever used. Highly recommended!',
      author: 'Adam',
      image: 'assets/images/client-one.jpg'
    },
    {
      text: 'Absolutely fantastic! Will use again for sure.',
      author: 'Smith',
      image: 'assets/images/client-two.jpg'
    },
    {
      text: 'Professional and reliable. Great experience all around.',
      author: 'Everett',
      image: 'assets/images/client-three.jpg'
    }
  ];

  ngAfterViewInit() {
    new Swiper('.mySwiper', {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      
    });
  }
}
