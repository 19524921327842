import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedded-architect-experts',
  templateUrl: './embedded-architect-experts.component.html',
  styleUrls: ['./embedded-architect-experts.component.scss']
})
export class EmbeddedArchitectExpertsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
