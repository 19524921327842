import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outsource-ad-operation',
  templateUrl: './outsource-ad-operation.component.html',
  styleUrls: ['./outsource-ad-operation.component.scss']
})
export class OutsourceAdOperationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
