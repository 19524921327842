import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance-accounts',
  templateUrl: './finance-accounts.component.html',
  styleUrls: ['./finance-accounts.component.scss']
})
export class FinanceAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
