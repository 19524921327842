import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-tax-preparer',
  templateUrl: './hire-tax-preparer.component.html',
  styleUrls: ['./hire-tax-preparer.component.scss']
})
export class HireTaxPreparerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
