import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-electrical-engineer',
  templateUrl: './hire-electrical-engineer.component.html',
  styleUrls: ['./hire-electrical-engineer.component.scss']
})
export class HireElectricalEngineerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
