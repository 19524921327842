import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graphic-web-design',
  templateUrl: './graphic-web-design.component.html',
  styleUrls: ['./graphic-web-design.component.scss']
})
export class GraphicWebDesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
