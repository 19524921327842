import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-web-designerion',
  templateUrl: './hire-web-designerion.component.html',
  styleUrls: ['./hire-web-designerion.component.scss']
})
export class HireWebDesignerionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
