<!--Banner Section-->
<section class="banner">
    <div class="m-intro">
        <div class="banner-text">
            <span class="subtitle">We Create Future Business</span>
            <h1>ITCRAYONS</h1>
            <p class="preamble">
                Website Design and Mobile App Development Company
            </p>
        </div>
        <div id="particleCanvas-Orange" class="e-particles-orange"></div>
        <div id="particleCanvas-Blue" class="e-particles-blue"></div>
    </div>
        <!-- <app-carousel [slides]="mainBannersSlides" [options]="mainBanners"></app-carousel> -->
</section>
<!--/.Banner Section-->
<!--Technologies Section
<section class="technologies-sec">
    <div class="container">
        <h2>Our Technology Stack</h2>
        <p>Our Technology Offerings are Holistic and includes popular variants of Open sources Frameworks than enables
            agile and lean development. We choose appropriate technology stack for our customer to help them achieve
            their business goals, be it an enterprise or a Startup, Our solution architects ensure robust and scalable
            systems keeping in mind the Economics of Delivery and ownership.</p>
        <ul class="tech-services">
            <li>
                <figure class="nod-ico">
                    <img src="assets/images/node-ico.png" alt="Node.js Development, Node.js Development Company, Node.js Web Development, Node.js Application Development, Node.js Frameworks, Node.js REST API Development, Node.js Real-time Applications, Node.js Microservices, Node.js CMS Development, Node.js E-commerce Development, Node.js Mobile App Development, Node.js Consulting Services, Node.js Performance Optimization, Node.js Security Best Practices, Node.js Development Tools, Node.js vs. Other Technologies, Node.js Tutorials, Node.js Community, Node.js Best Practices, Node.js Case Studies" width="" height="" loading="lazy"/>
                </figure>
                <h5>Node</h5>
            </li>
            <li>
                <figure class="dot-ico">
                    <img src="assets/images/Dot-Net-ico.png" alt="NET Development, .NET Development Company, ASP.NET Development,.NET Core Development, C# Development, .NET MVC Development, .NET API Development, .NET Microservices, Xamarin Development, Azure Cloud Development, SharePoint Development, SQL Server Development, Visual Studio Development, .NET Frameworks, .NET Performance Optimization, .NET Security Best Practices, .NET Development Tools, Comparing .NET to Other Technologies, .NET Tutorials, .NET Community, .NET Certification" width="" height="" loading="lazy"/>
                </figure>
                <h5>Dot Net</h5>
            </li>
            <li>
                <figure class="Java">
                    <img src="assets/images/Java.png" alt="Java Development, Java Development Company, Java Web Development, Spring Framework Development, Java EE Developmen, Java Mobile App Development, Java RESTful API Development, Java Microservices, Hibernate Development, Java Swing Development, JavaFX Development, Java Performance Optimization, Java Security Best Practices, Java Development Tools, Comparing Java to Other Technologies, Java Tutorials, Java Community, Java Certification, Java 8 Features, Java Design Patterns, Java Code Optimization, Java Application Testing" width="" height="" loading="lazy"/>
                </figure>
                <h5>Java</h5>
            </li>
            <li>
                <figure class="php-ico">
                    <img src="assets/images/Php.png" alt="PHP Development, PHP Development Company, PHP Web Development, Laravel Development, Symfony Development, CodeIgniter Development, Zend Framework Development, WordPress Development, Drupal Development, Joomla Development, PHP E-commerce Development, PHP API Development, PHP Performance Optimization, PHP Security Best Practices, PHP Development Tools, Comparing PHP to Other Technologies, PHP Tutorials, PHP Community, PHP Certification, PHP 8 Features, PHP Object-Oriented Programming (OOP), PHP Database Integration" width="" height="" loading="lazy"/>
                </figure>
                <h5>PHP</h5>
            </li>
            <li>
                <figure class="html5-ico">
                    <img src="assets/images/html5.png" alt="HTML5, HTML5 Tutorial, HTML5 Web Development, HTML5 Game Development, HTML5 Mobile App Development, HTML5 Responsive Web Design, HTML5 Animation, HTML5 Forms, HTML5 Canvas, HTML5 Video and Audio, HTML5 Accessibility, HTML5 SEO Best Practices, HTML5 Semantic Markup, HTML5 vs. Older HTML Versions, HTML5 Browser Support, HTML5 Frameworks, HTML5 and CSS3, HTML5 Mobile Web Apps, HTML5 Game Engines, HTML5 Web Components, HTML5 Security Best Practices, HTML5 Polyfills" width="" height="" loading="lazy"/>
                </figure>
                <h5>HTML</h5>
            </li>
            <li>
                <figure class="css3-ico">
                    <img src="assets/images/css3.png" alt="CSS3, CSS3 Tutorials, CSS3 Web Design, CSS3 Animation, CSS3 Flexbox, CSS3 Grid Layout, CSS3 Transitions, CSS3 Transformations, CSS3 Responsive Design, CSS3 Preprocessors, CSS3 Frameworks, CSS3 vs. CSS, CSS3 Browser Compatibility, CSS3 Layout Techniques, CSS3 Box Model, CSS3 Typography, CSS3 Flexibility, CSS3 Best Practices, CSS3 Animation Libraries, CSS3 Color and Gradients, CSS3 Shape and Clip Path, CSS3 Custom Properties (Variables), CSS3 Framework Integration" width="" height="" loading="lazy"/>
                </figure>
                <h5>CSS3</h5>
            </li>
            <li>
                <figure class="angular-ico">
                    <img src="assets/images/angular.png" alt="AngularJS, AngularJS Tutorial, AngularJS Development, AngularJS Directives, AngularJS Controllers, AngularJS Services, AngularJS Routing, AngularJS Dependency Injection, AngularJS Testing, AngularJS vs. Angular, Angular Development, Angular Tutorial, Angular Framework, Angular Components, Angular Directives, Angular Services, Angular Routing, Angular Dependency Injection, Angular Testing, Angular Best Practices, Angular Forms, Angular Animations, Angular State Management, Angular CLI, , Angular Material, Angular Security, Angular Unit Testing, Angular Integration" width="" height="" loading="lazy">
                </figure>
                <h5>Angular</h5>
            </li>
            <li>
                <figure class="dru-ico">
                    <img src="assets/images/dru-ico.png" alt="Drupal, Drupal Tutorial, Drupal Development, Drupal Themes, Drupal Modules, Drupal Theming, Drupal Site Building, Drupal SEO, Drupal Security, Drupal Upgrades, Drupal Performance Optimization, Drupal eCommerce, Drupal vs. Other CMS, Drupal 8 and Drupal 9, Drupal Community, Drupal Distributions, Drupal Multilingual Sites, Drupal Content Strategy, Drupal Data Migration, Drupal Accessibility, Drupal Case Studies, Drupal User Experience (UX)" width="" height="" loading="lazy">
                </figure>
                <h5>Drupal</h5>
            </li>
            <li>
                <figure class="magento-ico">
                    <img src="assets/images/magento.png" alt="Magento, Magento Tutorial, Magento Development,Magento Themes, Magento Extensions, Magento eCommerce, Magento SEO, Magento Security, Magento Upgrades, Magento Performance Optimization, Magento vs. Other eCommerce Platforms, Magento Community, Magento Mobile App Development, Magento Payment Gateways, Magento Inventory Management, Magento Customization, Magento Data Migration, Magento Marketing Strategies, Magento User Experience (UX), Magento B2B Solutions, Magento Case Studies, Magento Hosting" width="" height="" loading="lazy">
                </figure>
                <h5>Magento</h5>
            </li>
            <li>
                <figure class="wordpresss-ico">
                    <img src="assets/images/wordpresss.png" alt="WordPress, WordPress Tutorial, WordPress Development, WordPress Themes, WordPress Plugins, WordPress SEO, WordPress Security, WordPress Updates, WordPress Performance Optimization, WordPress vs. Other CMS, WordPress Community, WordPress Blogging, WordPress eCommerce, WordPress Multilingual Sites, WordPress Customization, WordPress Data Migration, WordPress Content Strategy, WordPress User Experience (UX), WordPress Hosting, WordPress Gutenberg Editor, WordPress Maintenance, WordPress Case Studies, WordPress Blog Monetization, WordPress Page Speed" width="" height="" loading="lazy">
                </figure>
                <h5>Wordpress</h5>
            </li>
        </ul>
    </div>
</section>
Technologies Section-->
<!--Services Section-->
<section class="services-sec">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-sm-10 mb-5 text-center">
                <h3><span>Our </span>Services</h3>
                <p>Since our establishment, we have been providing online-success through world-class IT outsourcing
                    services, be it web / mobile app development, software development or dedicated development teams.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="service-col">
                    <figure></figure>
                    <h4><span>Product</span> Engineering</h4>
                    <p>
                        We follow industry's best practices for innovating, architecture, designing, developing,
                        testing, deploying, migrating & porting a software product.
                    </p>
                    <a routerLink="/services" href="javascript:void(0)" aria-label="Learn More about service" class="btn btn-primary">Learn More</a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-col">
                    <figure></figure>
                    <h4><span>Startup</span> Services</h4>
                    <p>
                        We build solutions specifically tailored for your business needs without burning a hole in your
                        pocket
                    </p>
                    <a routerLink="/services" href="javascript:void(0)" aria-label="Learn More about provideing services" class="btn btn-primary">Learn More</a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-col">
                    <figure></figure>
                    <h4><span>Enterprise</span> Solutions</h4>
                    <p>
                        Our Solution experts provide you with a strategic advantage for challenges that require
                        breakthrough solutions.
                    </p>
                    <a routerLink="/services" href="javascript:void(0)" aria-label="Learn More about given services" class="btn btn-primary">Learn More </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--/.Services Section-->
<section class="dev-section our-process">
    <div class="container">
        <h3 class="text-center">Our <span>Development</span> Process</h3>
        <div class="dev-sub-section">
         <img src="../../../assets/images/Itcrayons-Service-Provider.png" width="100%" height="100%"  loading="lazy" alt="Software Development Process, Agile Development Process, Waterfall Development Process, SDLC (Software Development Life Cycle), Iterative Development Process, DevOps Process, CI/CD Pipeline, Software Development Methodologies, Frontend Development Process, Backend Development Process, Mobile App Development Process, Web Development Process, Full Stack Development Process, Requirements Gathering Process, Prototyping Process,Quality Assurance Process, Deployment Process, Project Management in Development, Version Control Process, Code Review Process, Development Best Practices, Development Process Documentation, Development Process Automation, Scalable Development Process, Secure Development Process"/>
        </div>
    </div>
</section>
<!--Contact Section-->
<section class="contact-box m-0 home-page-contact">
    <div class="container">
        <article class="content-content">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <h3>Let's Connect</h3>
                    <div class="contact-form">
                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group user-icon icon" [ngClass]="{'invalid' 
                            : f.name.touched && f.name.errors}">
                                <input type="text" formControlName="name" class="form-control" placeholder="Name">
                                <div *ngIf="f.name.errors && (f.name.dirty || f.name.touched)" class="error-text mt-2">
                                    <div *ngIf="f.name.errors.required">
                                        Name is required.
                                    </div>
                                </div>
                            </div>
                                    <div class="form-group email-icon icon" [ngClass]="{'invalid' 
                            : f.email.touched && f.email.errors}">
                                        <input type="email" formControlName="email" class="form-control"
                                            placeholder="Email">
                                        <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)"
                                            class="error-text mt-2">
                                            <div *ngIf="f.email.errors.required">
                                                Email is required.
                                            </div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                        </div>
                                    </div>
                                    <div class="form-group phone-icon icon" [ngClass]="{'invalid' 
                            : f.phone.touched && f.phone.errors}">
                                        <input type="text" formControlName="phone" max="12" class="form-control"
                                            placeholder="Phone">
                                        <div *ngIf="f.phone.errors && (f.phone.dirty || f.phone.touched)"
                                            class="error-text mt-2">
                                            <div *ngIf="f.phone.errors.required">
                                                Phone is required.
                                            </div>
                                            <div *ngIf="f.phone.errors.pattern">Phone Number must be a valid Number
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group chat-icon icon" [ngClass]="{'invalid' 
                            : f.message.touched && f.message.errors}">
                                        <textarea class="form-control" formControlName="message" rows="5"
                                            placeholder="Message"></textarea>
                                        <div *ngIf="f.message.errors && (f.message.dirty || f.message.touched)"
                                            class="error-text mt-2">
                                            <div *ngIf="f.message.errors.required">
                                                Message is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center m-3">
                                <button class="btn btn-primary" type="submit" [disabled]="!contactForm.valid">Send
                                    Request</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!--/.Contact Section-->