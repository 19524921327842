<section class="abs-banner d-flex">
    <h1 class="text-center">Cookies Policy</h1>
</section>
<section class="cookies-section">
    <div class="container">
        <h1>Cookies Policy</h1>
        <div class="section">
            <h2>1. Introduction</h2>
            <p>1.1 This cookies policy explains what cookies are, how we use cookies on our website, and your options for managing cookies.</p>
        </div>
    
        <div class="section">
            <h2>2. What Are Cookies?</h2>
            <p>2.1 A cookie is a small file containing data that is sent from a web server to your web browser and stored on your device. Each time you visit our website, the cookie allows the server to recognize your device.</p>
        </div>
    
        <div class="section">
            <h2>3. Types of Cookies We Use</h2>
            <p>3.1 We use the following types of cookies on our website:</p>
            <ul>
                <li><strong>Essential Cookies:</strong> These cookies are necessary for the operation of our website. They enable you to navigate the site and use its features.</li>
                <li><strong>Performance Cookies:</strong> These cookies collect information about how visitors use our website, such as which pages are visited most often. This helps us improve the performance of our site.</li>
                <li><strong>Functional Cookies:</strong> These cookies allow the website to remember your preferences and provide enhanced features.</li>
                <li><strong>Targeting Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.</li>
            </ul>
        </div>
    
        <div class="section">
            <h2>4. How We Use Cookies</h2>
            <p>4.1 We use cookies to:</p>
            <ul>
                <li>Enhance your experience on our website.</li>
                <li>Analyze site traffic and user behavior.</li>
                <li>Personalize content and advertisements.</li>
                <li>Provide social media features.</li>
            </ul>
        </div>
    
        <div class="section">
            <h2>5. Managing Cookies</h2>
            <p>5.1 Most web browsers allow you to manage cookies through their settings. You can choose to block or delete cookies through your browser settings.</p>
            <p>5.2 For detailed information on how to manage cookies on different browsers, please visit the following links:</p>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
                <li><a href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
                <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                <li><a href="https://support.apple.com/kb/PH21411">Safari</a></li>
                <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft Edge</a></li>
            </ul>
            <p>5.3 Please note that blocking cookies may affect the functionality of our website.</p>
        </div>
    
        <div class="section">
            <h2>6. Changes to This Cookies Policy</h2>
            <p>6.1 We may update this cookies policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
        </div>
    
        <div class="section">
            <h2>7. Contact Us</h2>
            <p>7.1 If you have any questions about our cookies policy, please contact us at: itcrayonshq&#64;gmail.com.</p>
        </div>
    </div>
</section>
