import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

  constructor() {
   }


   experts = [
    {
      name: 'David',
      title: 'Senior Developer',
      bio: 'David is a full-stack developer with over 10 years of experience in web technologies.',
      photo: 'assets/images/senior-developer.png'
    },
    {
      name: 'Alina Johnsan',
      title: 'UI/UX Designer',
      bio: 'Alina is passionate about creating intuitive and beautiful user interfaces.',
      photo: 'assets/images/ui-designer.png'
    },
    {
      name: 'Mark',
      title: 'Data Scientist',
      bio: 'Mark specializes in data analytics and machine learning.',
      photo: 'assets/images/data-scientist.png'
    }
  ];

  ngOnInit(): void {
  }

}
