import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent  {
  @Input() slides: { id: number, img: string }[] = [];
  @Input() options:  {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    autoHeight: false,
    autoplay: true,
    autoWidth: true,
    navSpeed: 300,
    responsive: {
      0: {
        items: 1,
      },
    }
    navText: ['<', '>']
  };

}