import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-mechanical-electrical-plumbing-engineers',
  templateUrl: './hire-mechanical-electrical-plumbing-engineers.component.html',
  styleUrls: ['./hire-mechanical-electrical-plumbing-engineers.component.scss']
})
export class HireMechanicalElectricalPlumbingEngineersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
