import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NotFoundComponent } from './pages/error/not-found.component';
import { VideoComponent } from './video/video.component';
import { GuidesComponent } from './guides/guides.component';
import { FaqsComponent } from './faqs/faqs.component';
import { EbooksComponent } from './ebooks/ebooks.component';
import { AwardsAndRecognitionComponent } from './awards-and-recognition/awards-and-recognition.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { UserAgreementPolicyComponent } from './user-agreement-policy/user-agreement-policy.component';
import { BlogComponent } from './blog/blog.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'blogs', component: BlogComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'video', component: VideoComponent },
  { path: 'guides', component: GuidesComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'ebooks', component: EbooksComponent },
  { path: 'blog/:id', component: BlogDetailsComponent },
  { path: 'our-gallery', component: ImageGalleryComponent },
  { path: 'testimonial', component: TestimonialComponent },
  { path: 'our-experts', component: OurTeamComponent },
  { path: 'testimonial', component: TestimonialComponent },
  { path: 'awards', component: AwardsAndRecognitionComponent },
  { path: 'contact', component: ContactUsComponent },

  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'user-agreement', component: UserAgreementPolicyComponent },
  { path: 'cookies-policy', component: CookiesPolicyComponent },



  { path: 'services', component: ServicesComponent },
  { path: '404', component: NotFoundComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
