import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-records-management-clerk',
  templateUrl: './legal-records-management-clerk.component.html',
  styleUrls: ['./legal-records-management-clerk.component.scss']
})
export class LegalRecordsManagementClerkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
