import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-artificial-intelligence-specialists',
  templateUrl: './hire-artificial-intelligence-specialists.component.html',
  styleUrls: ['./hire-artificial-intelligence-specialists.component.scss']
})
export class HireArtificialIntelligenceSpecialistsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
